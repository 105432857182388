@font-face {
    font-family: "RotisSemiSerifW01-Roman";
    src: url("/layout/fonts/df75a53e-dbb3-455d-97fe-06cc05821fc8.woff2") format("woff2"),
    url("/layout/fonts/afaa3c4a-15e8-43f7-8055-7a6ec2236af2.woff") format("woff");
}

@font-face {
    font-family: "RotisSemiSerifW01-Bold";
    src: url("/layout/fonts/101186e5-c514-4101-aedd-ea6198751a11.woff2") format("woff2"),
    url("/layout/fonts/1cc7500b-6f46-439a-b015-7d9bad71b492.woff") format("woff");
}

@font-face {
    font-family: "RotisSansSerifW01-Light_735438";
    src: url("/layout/fonts/426cf71e-b8d1-4802-9690-1dcec0ef30c6.woff2") format("woff2"),
    url("/layout/fonts/8acd0e02-7a91-4973-9c61-755704217f62.woff") format("woff");
}

@font-face {
    font-family: "Rotis Sans Serif W01";
    src: url("/layout/fonts/979b67f1-28b4-4d3b-ab4d-551d4eaa3827.woff2") format("woff2"),
    url("/layout/fonts/60d6e9b1-72ed-49c7-bcd9-026d74b98deb.woff") format("woff");
}

@font-face {
    font-family: "RotisSansSerifW01-Bold";
    src: url("/layout/fonts/667fde85-ea2f-4851-a2d1-93456b293f5a.woff2") format("woff2"),
    url("/layout/fonts/a789b8d7-2c89-433b-8852-dc661d2fb383.woff") format("woff");
}

@font-face {
    font-family: "RotisSansSerifW01-Extra";
    src: url("/layout/fonts/75ec2be4-7969-4594-9c01-fb9c2add6376.woff2") format("woff2"),
    url("/layout/fonts/13f09a40-3a24-44ca-8083-7c5e67fe802c.woff") format("woff");
}